<template>
    <nav id="pagination" class="pagination is-centered" role="navigation" aria-label="pagination">
        <a class="pagination-previous" @click="previousPage" :disabled="isLoading || currentPage == 0">Previous</a>
        <a class="pagination-next" @click="nextPage" :disabled="isLoading || currentPage >= totalPages - 1">Next</a>

        <ul class="pagination-list">
            <li v-for="(page, index) in pageList" :key="'page' + index">
                <span v-if="page === '...'" class="pagination-ellipsis">&hellip;</span>
                <a v-else-if="currentPage == page - 1" class="pagination-link is-current is-primary">
                    {{ page }}
                </a>
                <a v-else class="pagination-link" @click="setPage(page - 1)" :aria-label="'Goto page ' + page">
                    {{ page }}
                </a>
            </li>
        </ul>
    </nav>
</template>

<script>
    export default {
        name: 'Pagination',
        props: {
            isLoading: {
                type: Boolean,
                default: true
            },
            currentPage: {
                type: Number,
                default: 0
            },
            totalPages: {
                type: Number,
                default: 0
            }
        },
        data() {
            return {
                pageList: [],
                page: -1,
                total: -1
            }
        },
        created() {
            if (this.currentPage !== this.page || this.totalPages != this.total) {
                this.createPageList();
                this.page = this.currentPage;
                this.total = this.totalPages;
            }
        },
        updated() {
            if (this.currentPage !== this.page || this.totalPages != this.total) {
                this.createPageList();
                this.page = this.currentPage;
                this.total = this.totalPages;
            }
        },
        methods: {
            createPageList() {
                var current = this.currentPage;
                var total = this.totalPages;

                if (total > 1 && total > current) {
                    const center = [current - 2, current - 1, current, current + 1, current + 2],
                        filteredCenter = center.filter((p) => p > 1 && p < total),
                        includeThreeLeft = current === 5,
                        includeThreeRight = current === total - 4,
                        includeLeftDots = current > 5,
                        includeRightDots = current < total - 4;

                    if (includeThreeLeft) {
                        filteredCenter.unshift(2);
                    }

                    if (includeThreeRight) {
                        filteredCenter.push(total - 1);
                    }

                    if (includeLeftDots) {
                        filteredCenter.unshift('...');
                    }

                    if (includeRightDots) {
                        filteredCenter.push('...');
                    }

                    this.pageList = [1, ...filteredCenter, total];
                }
                else {
                    this.pageList = [];
                }
            },
            setPage(page) {
                this.$emit('setPage', page);
            },
            previousPage() {
                if (this.currentPage > 0) {
                    this.$emit('setPage', this.currentPage - 1);
                }
            },
            nextPage() {
                if (this.currentPage < this.totalPages - 1) {
                    this.$emit('setPage', this.currentPage + 1);
                }
            }
        }
    }
</script>

<style>
</style>
