<template>
    <div ref="filterDropdown" :class="filterDropdownActive ? 'dropdown is-active is-right filter-dropdown' : 'dropdown filter-dropdown'" tabindex="1" @blur="handleComponentBlur">
        <div class="dropdown-trigger">
            <div class="button is-primary" :class="{ 'is-link': filtersApplied }" @click="filterDropdownActive = !filterDropdownActive" aria-haspopup="true" aria-controls="dropdown-menu1">
                <span v-if="filtersApplied > 0">Filters({{ filtersApplied }})</span>
                <span v-else>Add Filter</span>
                <span class="icon is-small">
                <font-awesome-icon :icon="['fa', 'filter']" />
                </span>
            </div>
        </div>
        <div class="dropdown-menu" id="dropdown-menu1" role="menu">
            <div class="dropdown-content">
                <div v-if="displayAccount" class="dropdown-item">
                    <div class="field">
                        <label class="label">Account</label>
                        <div class="field has-addons">
                            <div v-if="!accountDisplayName" :class="accountDropdownActive ? 'dropdown is-right is-active' : 'dropdown is-right'">
                                <div class="dropdown-trigger is-right">
                                    <div class="control has-icons-left">
                                        <input type="text" placeholder="Search accounts..." aria-controls="dropdown-menu" @click="accountDropdownActive = !accountDropdownActive" class="input is-transparent" ref="accountDropdownFilterRef" v-model="accountDropdownFilter" @blur="delayCloseDropdown('account')">
                                        <span class="icon is-left">
                                            <font-awesome-icon :icon="['fa', 'search']" />
                                        </span>
                                    </div>
                                </div>
                                <div v-if="accountDropdownActive" class="dropdown-menu" id="dropdown-menu2" role="menu">
                                    <div class="dropdown-content" id="account-dropdown">
                                        <a v-for="account in accountsFiltered" :key="account.publicId"
                                           class="dropdown-item" @click="accountSelected(account)">
                                            {{ account.name }}
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div v-else>
                                <span class="tag is-primary is-medium">
                                    {{ accountDisplayName }}
                                    <button class="delete is-small ml-2" @click="clearSelectedAccount()"></button>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-if="displayAccountStatus" class="dropdown-item">
                    <div class="field is-fullwidth">
                        <label class="label">Account Status</label>
                        <div :class="statusDropdownActive ? 'dropdown is-active' : 'dropdown'">
                            <div class="dropdown-trigger">
                                <div class="button" aria-haspopup="true" aria-controls="dropdown-menu" @click="statusDropdownActive = !statusDropdownActive" tabindex="3" @blur="delayCloseDropdown('status')">
                                    <span v-if="!accountStatus">Select a status...</span>
                                    <span>{{ accountStatusTypeDictionary[accountStatus] }}</span>
                                    <span class="icon is-small">
                                        <font-awesome-icon :icon="['fa', 'angle-down']" />
                                    </span>
                                </div>
                            </div>
                            <div class="dropdown-menu" v-if="statusDropdownActive" id="dropdown-menu4" role="menu">
                                <div class="dropdown-content">
                                    <a v-for="(index, statusKey) in accountStatusTypeDictionaryFiltered" :key="'accountStatus' + index"
                                       :class="accountStatus == statusKey ? 'dropdown-item is-active' : 'dropdown-item'" @click="setAccountStatus(statusKey)">
                                        {{ accountStatusTypeDictionary[statusKey] }}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-if="displayAccountIntegrations" class="dropdown-item">
                    <div class="field is-fullwidth">
                        <label class="label">Integration</label>
                        <div :class="accountIntegrationDropdownActive ? 'dropdown is-active' : 'dropdown'">
                            <div class="dropdown-trigger">
                                <div class="button" aria-haspopup="true" aria-controls="dropdown-menu" @click="accountIntegrationDropdownActive = !accountIntegrationDropdownActive" tabindex="4" @blur="delayCloseDropdown('accountIntegration')">
                                    <span v-if="!accountIntegrationSelectedIdx">Select ...</span>
                                    <span>{{ accountIntegrationDictionary[accountIntegrationSelectedIdx] }}</span>
                                    <span class="icon is-small">
                                        <font-awesome-icon :icon="['fa', 'angle-down']" />
                                    </span>
                                </div>
                            </div>
                            <div class="dropdown-menu" v-if="accountIntegrationDropdownActive" id="dropdown-menu4" role="menu">
                                <div class="dropdown-content">
                                    <a v-for="(index, integrationKey) in accountIntegrationFiltered" :key="'accountIntegration' + index"
                                       :class="accountIntegrationSelectedIdx == integrationKey ? 'dropdown-item is-active' : 'dropdown-item'" @click="setAccountIntegration(integrationKey)">
                                        {{ accountIntegrationDictionary[integrationKey] }}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-if="displayUserRole" class="dropdown-item">
                    <div class="field is-fullwidth">
                        <label class="label">User Role</label>
                        <div :class="roleDropdownActive ? 'dropdown is-active' : 'dropdown'">
                            <div class="dropdown-trigger">
                                <div class="button" aria-haspopup="true" aria-controls="dropdown-menu" @click="roleDropdownActive = !roleDropdownActive" tabindex="2" @blur="delayCloseDropdown('role')">
                                    <span v-if="!userRole">Select a role...</span>
                                    <span>{{ roleTypeDictionary[userRole] }}</span>
                                    <span class="icon is-small">
                                        <font-awesome-icon :icon="['fa', 'angle-down']" />
                                    </span>
                                </div>
                            </div>
                            <div class="dropdown-menu" v-if="roleDropdownActive" id="dropdown-menu3" role="menu">
                                <div class="dropdown-content">
                                    <a v-for="(index, roleKey) in roleTypeDictionaryFiltered" :key="'userRole' + index"
                                       :class="userRole == roleKey ? 'dropdown-item is-active' : 'dropdown-item'" @click="setUserRole(roleKey)">
                                        {{ roleTypeDictionary[roleKey] }}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-if="displayUserStatus" class="dropdown-item">
                    <div class="field is-fullwidth">
                        <label class="label">User Status</label>
                        <div :class="statusDropdownActive ? 'dropdown is-active' : 'dropdown'">
                            <div class="dropdown-trigger">
                                <div class="button" aria-haspopup="true" aria-controls="dropdown-menu" @click="statusDropdownActive = !statusDropdownActive" tabindex="3" @blur="delayCloseDropdown('status')">
                                    <span v-if="!userStatus">Select a status...</span>
                                    <span>{{ userStatusTypeDictionary[userStatus] }}</span>
                                    <span class="icon is-small">
                                        <font-awesome-icon :icon="['fa', 'angle-down']" />
                                    </span>
                                </div>
                            </div>
                            <div class="dropdown-menu" v-if="statusDropdownActive" id="dropdown-menu4" role="menu">
                                <div class="dropdown-content">
                                    <a v-for="(index, statusKey) in userStatusTypeDictionaryFiltered" :key="'userStatus' + index"
                                       :class="userStatus == statusKey ? 'dropdown-item is-active' : 'dropdown-item'" @click="setUserStatus(statusKey)">
                                        {{ userStatusTypeDictionary[statusKey] }}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-if="displayTextingServices" class="dropdown-item">
                    <div class="field is-fullwidth">
                        <label class="label">Texting Service</label>
                        <div :class="textingServicesDropdownActive ? 'dropdown is-active' : 'dropdown'">
                            <div class="dropdown-trigger">
                                <div class="button" aria-haspopup="true" aria-controls="dropdown-menu" @click="textingServicesDropdownActive = !textingServicesDropdownActive" tabindex="4" @blur="delayCloseDropdown('textingService')">
                                    <span v-if="!textingServicesSelectedIdx">Select ...</span>
                                    <span>{{ textingServicesDictionary[textingServicesSelectedIdx] }}</span>
                                    <span class="icon is-small">
                                        <font-awesome-icon :icon="['fa', 'angle-down']" />
                                    </span>
                                </div>
                            </div>
                            <div class="dropdown-menu" v-if="textingServicesDropdownActive" id="dropdown-menu4" role="menu">
                                <div class="dropdown-content">
                                    <a v-for="(index, textingServiceKey) in textingServicesFiltered" :key="'textingService' + index"
                                       :class="textingServicesSelectedIdx == textingServiceKey ? 'dropdown-item is-active' : 'dropdown-item'" @click="setTextingService(textingServiceKey)">
                                        {{ textingServicesDictionary[textingServiceKey] }}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-if="displayLineIntegrations" class="dropdown-item">
                    <div class="field is-fullwidth">
                        <label class="label">Integration</label>
                        <div :class="lineIntegrationDropdownActive ? 'dropdown is-active' : 'dropdown'">
                            <div class="dropdown-trigger">
                                <div class="button" aria-haspopup="true" aria-controls="dropdown-menu" @click="lineIntegrationDropdownActive = !lineIntegrationDropdownActive" tabindex="4" @blur="delayCloseDropdown('lineIntegration')">
                                    <span v-if="!lineIntegrationSelectedIdx">Select ...</span>
                                    <span>{{ lineIntegrationDictionary[lineIntegrationSelectedIdx] }}</span>
                                    <span class="icon is-small">
                                        <font-awesome-icon :icon="['fa', 'angle-down']" />
                                    </span>
                                </div>
                            </div>
                            <div class="dropdown-menu" v-if="lineIntegrationDropdownActive" id="dropdown-menu4" role="menu">
                                <div class="dropdown-content">
                                    <a v-for="(index, integrationKey) in lineIntegrationFiltered" :key="'lineIntegration' + index"
                                       :class="lineIntegrationSelectedIdx == integrationKey ? 'dropdown-item is-active' : 'dropdown-item'" @click="setLineIntegration(integrationKey)">
                                        {{ lineIntegrationDictionary[integrationKey] }}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-if="displayLineIsEnabled" class="dropdown-item">
                    <div class="field is-fullwidth">
                        <label class="label">Is Enabled</label>
                        <div :class="lineIsEnabledDropdownActive ? 'dropdown is-active' : 'dropdown'">
                            <div class="dropdown-trigger">
                                <div class="button" aria-haspopup="true" aria-controls="dropdown-menu" @click="lineIsEnabledDropdownActive = !lineIsEnabledDropdownActive" tabindex="4" @blur="delayCloseDropdown('lineIsEnabled')">
                                    <span v-if="!lineIsEnabled">Select ...</span>
                                    <span>{{ lineIsEnabledDictionary[lineIsEnabled] }}</span>
                                    <span class="icon is-small">
                                        <font-awesome-icon :icon="['fa', 'angle-down']" />
                                    </span>
                                </div>
                            </div>
                            <div class="dropdown-menu" v-if="lineIsEnabledDropdownActive" id="dropdown-menu4" role="menu">
                                <div class="dropdown-content">
                                    <a v-for="(index, key) in lineIsEnabledDictionaryFiltered" :key="'lineIsEnabled' + index"
                                       :class="lineIsEnabled == key ? 'dropdown-item is-active' : 'dropdown-item'" @click="setLineIsEnabled(key)">
                                        {{ lineIsEnabledDictionary[key] }}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <hr class="dropdown-divider">

                <div class="dropdown-item">
                    <div class="field">
                        <button class="button is-text clear-button" @click="clearFilters()">Clear</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { securePost } from '../secureFetch.js';

    export default {
        name: 'FilterDropdown',
        props: {
            displayAccount: {
                type: Boolean,
                default: false
            },
            defaultAccountStatus: null,
            displayAccountStatus: {
                type: Boolean,
                default: false
            },
            defaultAccountIntegration: null,
            displayAccountIntegrations: {
                type: Boolean,
                default: false,
            },
            defaultAccountPublicId: null,
            displayUserRole: {
                type: Boolean,
                default: false
            },
            defaultUserRole: null,
            displayUserStatus: {
                type: Boolean,
                default: false
            },
            defaultUserStatus: null,
            filtersApplied : 0,
            defaultTextingService: null,
            displayTextingServices: {
              type: Boolean,
              default: false,
            },
            defaultLineIntegration: null,
            displayLineIntegrations: {
              type: Boolean,
              default: false,
            },
            defaultLineIsEnabled: null,
            displayLineIsEnabled: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            hostUrl: function() {
                return this.$store.state.hostUrl;
            },
            accountsFiltered: function() {
                var accountFilter = "";
                if (this.accountDropdownFilter) {
                    accountFilter = this.accountDropdownFilter;
                }
                return this.accounts.filter(function (account) {
                    return account.name.toUpperCase().match(accountFilter.toUpperCase())
                });
            },
            textingServicesFiltered: function() {
              let filtered = JSON.parse(JSON.stringify(this.textingServicesDictionary));
              delete filtered[0];
              return filtered;
            },
            accountIntegrationFiltered: function () {
                let filtered = JSON.parse(JSON.stringify(this.accountIntegrationDictionary));
                delete filtered[0];
                return filtered;
            },
            lineIntegrationFiltered: function() {
              let filtered = JSON.parse(JSON.stringify(this.lineIntegrationDictionary));
              delete filtered[0];
              return filtered;
            },
            roleTypeDictionaryFiltered: function() {
              var rolesFiltered = JSON.parse(JSON.stringify(this.roleTypeDictionary));
              delete rolesFiltered[0];
              return rolesFiltered;
            },
            accountStatusTypeDictionaryFiltered: function () {
                var statusFiltered = JSON.parse(JSON.stringify(this.accountStatusTypeDictionary));
                delete statusFiltered[0];
                return statusFiltered;
            },
            userStatusTypeDictionaryFiltered: function() {
              var statusFiltered = JSON.parse(JSON.stringify(this.userStatusTypeDictionary));
              delete statusFiltered[0];
              return statusFiltered;
            },
            lineIsEnabledDictionaryFiltered: function () {
              var isEnabledFiltered = JSON.parse(JSON.stringify(this.lineIsEnabledDictionary));
              delete isEnabledFiltered[0];
              return isEnabledFiltered;
            }
        },
        data() {
            return {
                accounts: [],
                accountDropdownFilter: "",
                accountDropdownActive: false,
                accountDisplayName: '',
                filterDropdownActive: false,
                statusDropdownActive: false,
                roleDropdownActive: false,
                roleTypeDictionary: { 0: "Unknown", 1: 'Standard', 2: 'Admin', 3: 'Integration', 4: 'Support' },
                userStatusTypeDictionary: { 0: "Unknown", 1: 'Active', 2: 'Deleted', 3: 'Pending', 4: 'Inactive' },
                userStatus: null,
                userRole: null,
                textingServicesDictionary: { 1: 'Zipwhip', 2: 'Bandwidth', 3: 'Sinch', 4: 'RingCentral', 5: 'Twilio' },
                textingServicesDropdownActive: false,
                textingServicesSelectedIdx: null,
                lineIntegrationDictionary: { 1: 'Internal', 2: 'RingCentral MVP', 3: 'Nice Legacy Chat', 7: 'Nice DFO BYOC', 4: 'Genesys CCaaS', 5: 'Genesys UCaaS', 6: 'None', 8: 'RingCX', 9: 'Genesys Open Messaging' },
                lineIntegrationDropdownActive: false,
                lineIntegrationSelectedIdx: null,
                accountStatusTypeDictionary: { 0: "Unknown", 1: 'Active', 2: 'Deleted', 3: 'Inactive' },
                accountStatus: null,
                accountIntegrationDictionary: { 1: 'Internal', 2: 'RingCentral', 3: 'Nice', 4: 'Genesys CCaaS', 5: 'Genesys UCaaS', 6: 'Meevo' },
                accountIntegrationDropdownActive: false,
                accountIntegrationSelectedIdx: null,
                lineIsEnabledDictionary: { 1: 'True', 2: 'False' },
                lineIsEnabledDropdownActive: false,
                lineIsEnabled: null
            }
        },
        mounted() {
            if (this.displayAccount) {
                this.searchAccounts();
            }

            if (this.displayAccountStatus && this.accountStatus in this.accountStatusTypeDictionary) {
                this.accountStatus = parseInt(this.defaultAccountStatus);
            }

            if (this.displayAccountIntegrations && this.defaultAccountIntegration in this.accountIntegrationDictionary) {
                this.accountIntegrationSelectedIdx = parseInt(this.defaultAccountIntegration);
            }

            if (this.displayUserRole && this.defaultUserRole in this.roleTypeDictionary) {
                this.userRole = this.defaultUserRole;
            }

            if (this.displayUserStatus && this.defaultUserStatus in this.userStatusTypeDictionary) {
                this.userStatus = this.defaultUserStatus;
            }

            if (this.displayLineIntegrations && this.defaultLineIntegration in this.lineIntegrationDictionary) {
                this.lineIntegrationSelectedIdx = parseInt(this.defaultLineIntegration);
            }

            if (this.displayTextingServices && this.defaultTextingService in this.textingServicesDictionary) {
                this.textingServicesSelectedIdx = parseInt(this.defaultTextingService);
            }

            if (this.displayLineIsEnabled && this.defaultLineIsEnabled in this.lineIsEnabledDictionary) {
                this.lineIsEnabled = parseInt(this.defaultLineIsEnabled);
            }
        },
        methods: {
            delayCloseDropdown(target) {
                setTimeout(() => {
                  switch (target) {
                    case 'account':
                      this.accountDropdownActive = false;
                      break;
                    case 'status':
                      this.statusDropdownActive = false;
                      break;
                    case 'role':
                      this.roleDropdownActive = false;
                      break;
                    case 'textingService':
                      this.textingServicesDropdownActive = false;
                      break;
                    case 'lineIntegration':
                      this.lineIntegrationDropdownActive = false;
                      break;
                    default:
                      break;
                  }
                }, 500);
                this.$refs['filterDropdown'].focus();
            },
            accountDropdownSelected() {
                this.accountDropdownActive = !this.accountDropdownActive;
                this.$nextTick(() => {
                    const filterRef = this.$refs.accountDropdownFilterRef;
                    filterRef.focus();
                });
            },
            accountSelected(account) {
                this.accountDisplayName = account.name;
                this.accountDropdownFilter = '';
                this.accountDropdownActive = false;
                this.$emit('accountSelected', account.publicId);
            },
            clearSelectedAccount() {
                this.accountDisplayName = '';
                this.accountDropdownFilter = '';
                this.accountDropdownActive = false;
                this.$refs['filterDropdown'].focus();
                this.$emit('accountSelected', null);
            },
            clearFilters() {
                this.userStatus = null;
                this.userRole = null;
                this.accountDisplayName = '';
                this.accountDropdownFilter = '';
                this.accountDropdownActive = false;
                this.accountStatus = null;
                this.accountIntegrationSelectedIdx = null;
                this.textingServicesSelectedIdx = null;
                this.lineIntegrationSelectedIdx = null;
                this.lineIsEnabled = null;
                this.$refs['filterDropdown'].focus();
                this.$emit('clearFilters');
            },
            setUserStatus(status) {
                this.userStatus = parseInt(status);
                this.statusDropdownActive = false;
                this.$emit('userStatusSelected', this.userStatus);
            },
            setUserRole(role) {
                this.userRole = parseInt(role);
                this.roleDropdownActive = false;
                this.$emit('userRoleSelected', this.userRole);
            },
            setTextingService(serviceCode) {
                this.textingServicesSelectedIdx = parseInt(serviceCode);
                this.textingServicesDropdownActive = false;
                this.$emit('textingServiceSelected', { code: serviceCode, name: this.textingServicesDictionary[this.textingServicesSelectedIdx] });
            },
            setLineIntegration(integrationCode) {
                this.lineIntegrationSelectedIdx = parseInt(integrationCode);
                this.lineIntegrationDropdownActive = false;
                this.$emit('lineIntegrationSelected', { code: integrationCode, name: this.lineIntegrationDictionary[this.lineIntegrationSelectedIdx] });
            },
            setLineIsEnabled(isEnabled) {
                this.lineIsEnabled = parseInt(isEnabled);
                this.lineIsEnabledDropdownActive = false;
                this.$emit('lineIsEnabledSelected', this.lineIsEnabled);
            },
            setAccountStatus(status) {
                this.accountStatus = parseInt(status);
                this.statusDropdownActive = false;
                this.$emit('accountStatusSelected', this.accountStatus);
            },
            setAccountIntegration(integrationCode) {
                this.accountIntegrationSelectedIdx = parseInt(integrationCode);
                this.accountIntegrationDropdownActive = false;
                this.$emit('accountIntegrationSelected', { code: integrationCode, name: this.accountIntegrationDictionary[this.accountIntegrationSelectedIdx] });
            },
            searchAccounts() {
                this.accounts = [];
                this.errorMessage = null;

                var postBody = {
                    searchTerm: null,
                    page: 0,
                    resultsPerPage: 9999
                };

                securePost(this.hostUrl + "v1/account/search-accounts", postBody)
                    .then(data => {
                        if (data && data.success) {
                            this.accounts = data.accounts.sort((a, b) => a.name.localeCompare(b.name));
                            this.setDefaultAccount();
                        }
                        else {
                            this.$emit('error', "Error Retrieving Accounts: " + data.message);
                        }
                    });
            },
            setDefaultAccount() {
                if (this.defaultAccountPublicId != null) {
                    var account = this.accounts.find(account => account.publicId === parseInt(this.defaultAccountPublicId));
                    
                    if (account) {
                        this.accountDisplayName = account.name;
                    }
                }
            },
            handleComponentBlur(event) {
                if (!event.currentTarget.contains(event.relatedTarget)) {
                    this.filterDropdownActive = false;
                }
            }
        }
    };
</script>

<style scoped>
    #dropdown-menu1 {
        min-width: 16rem;
    }
    #account-dropdown {
        max-height: 24em;
        overflow: auto;
    }
    .clear-button {
        text-decoration: none !important;
        color: hsl(212, 50%, 45%);
    }
        .clear-button:hover {
            background-color: hsl(213, 45%, 10%);
            color: hsl(212, 50%, 45%);
        }
    .is-fullwidth > .dropdown {
        width: 100%;
    }
    .is-fullwidth .dropdown-trigger {
        width: 100%
    }
    .is-fullwidth .button {
        display: flex;
        width: 100%;
        justify-content: space-between
    }
</style>